// Webpack Imports
import * as bootstrap from "bootstrap";
import AOS from "aos";
import Swiper from "swiper/bundle";
import $ from "jquery";
import "bs5-lightbox";
import "./components/scrollNav";

window.jQuery = $;
window.$ = $;

function isMobile(width) {
    var windowWidth = window.innerWidth;
    var ismobile = window.innerWidth <= width ? true : false;
    return ismobile;
}

var swiper = new Swiper(".timeline", {
    slidesPerView: 3,
    spaceBetween: 30,
    freeMode: false,
    scrollbar: {
        el: ".swiper-scrollbar",
        draggable: true,
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
            spaceBetween: 40,
        },

        780: {
            slidesPerView: 2,
            spaceBetween: 30,
        },

        1024: {
            slidesPerView: 3,
            spaceBetween: 30,
        },
    },
    mousewheel: false,
});

var swiperLeistung = new Swiper(".leistung-scroll", {
    slidesPerView: 3,
    spaceBetween: 30,
    freeMode: false,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    scrollbar: {
        el: ".swiper-scrollbar",
        draggable: true,
    },
    hashNavigation: {
        watchState: true,
        replaceState: true,
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
            spaceBetween: 50,
        },

        780: {
            slidesPerView: 2,
            spaceBetween: 50,
        },

        1024: {
            slidesPerView: 2,
            spaceBetween: 50,
        },
    },
    mousewheel: false,
});

var swiperReferenz = new Swiper(".referenz-scroll", {
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    scrollbar: {
        el: ".swiper-scrollbar",
        draggable: true,
    },
});

(function () {
    "use strict";

    AOS.init({
        duration: 950,
    });

    // remove empty content
    var item = $(".main").find("div[itemprop=articleBody]");
    if (item.children().length == 0) {
        item.parent().parent().parent().parent().remove();
    }

    $(".open-menu,.close").on("click", function (ev) {
        if ($(".menu-overlay").hasClass("open")) {
            $(".menu-overlay").removeClass("open");
        } else {
            $(".menu-overlay").addClass("open");
        }

        ev.preventDefault();
    });

    // Focus input if Searchform is empty
    [].forEach.call(document.querySelectorAll(".search-form"), (el) => {
        el.addEventListener("submit", function (e) {
            var search = el.querySelector("input");
            if (search.value.length < 1) {
                e.preventDefault();
                search.focus();
            }
        });
    });

    //Preloader
    let preloaderFadeOutTime = 800;
    function hidePreloader() {
        var preloader = $(".spinner-wrapper");
        preloader.delay(500).fadeOut(preloaderFadeOutTime);
    }
    hidePreloader();
})();

// When the user scrolls the page, execute myFunction
window.onscroll = function () {
    setSticky();
    setShrink();
};

// Get the navbar
var navbar = document.getElementById("navbar");
var navbarShrink = document.getElementById("navbar__shrink");

if (navbar) {
    // Get the offset position of the navbar
    var sticky = navbar.offsetTop;
}

// Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
function setSticky() {
    if (navbar) {
        if (window.pageYOffset >= sticky) {
            navbar.classList.add("sticky", "shrink", "shrink__home");
        } else {
            navbar.classList.remove("sticky", "shrink", "shrink__home");
        }
    }
}

function setShrink() {
    if (!isMobile(768)) {
        if (navbarShrink) {
            if (window.pageYOffset >= 1) {
                navbarShrink.classList.add("shrink");
                $(".wrapper").css("margin-top", 0);
            } else {
                navbarShrink.classList.remove("shrink");
                $(".wrapper").css("margin-top", 120);
            }
        }
    }
}

let topBtn = document.querySelector(".top-btn");
topBtn.onclick = () => window.scrollTo({ top: 0, behavior: "smooth" });

let scrollerPhone = document.querySelector(".scroller__phone");
let scrollerPhoneNumber = document.querySelector(".scroller__phone-number");
scrollerPhone.onclick = () => scrollerPhoneNumber.classList.toggle("open");

let scrollerContactTitle = document.querySelector(".scroller__contact-title");
let scrollerContactForm = document.querySelector(".scroller__contact-form");
scrollerContactTitle.onclick = () =>
    scrollerContactForm.classList.toggle("open");

(function () {
    "use strict";

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.querySelectorAll(".needs-validation");
    var cf = $("#cf");

    // Loop over them and prevent submission
    Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
            "submit",
            function (event) {
                if (!form.checkValidity()) {
                    event.preventDefault();
                    event.stopPropagation();
                } else {
                    $.ajax({
                        type: "POST",
                        url: "contact.php",
                        dataType: "json",
                        cache: false,
                        data: $(this).serialize(),
                        success: function (data) {
                            console.log(data);
                            if (data.info == "error") {
                                console.log(data.msg);
                            } else if (data.info == "success") {
                                console.log(data.msg);
                                $(".alert.alert-success").parent().show();
                                $("#cf")[0].reset();
                            }
                        },
                    });

                    $(".alert.alert-success").parent().show();
                    $("#cf")[0].reset();
                }

                form.classList.add("was-validated");
            },
            false
        );
    });
})();
